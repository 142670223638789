import React from "react";
import { Card, CardBody, Gallery, GalleryItem } from "@patternfly/react-core";
import { Link } from "react-router-dom";

const items = [
  {
    name: "Ski",
    image: "ski-icon.png",
    desc: "Page dedicated to skiing",
    type: "a",
    link: "https://skierz.net/",
  },
  {
    name: "Golf",
    image: "golf-icon.png",
    desc: "Page dedicated to golfing",
    link: "./golf",
  },
  {
    name: "Tennis",
    image: "tennis-icon.png",
    desc: "Tennis log",
    link: "./tennis",
  },
  {
    name: "Humor",
    image: "humor-icon.png",
    desc: "Things I find funny",
    link: "./humor",
  },

  {
    name: "Flying",
    image: "flying-icon.png",
    desc: "Up, Up, and Away",
    link: "./flying",
    disabled: true,
  },
  {
    name: "Old PHP Ski Page",
    image: "old-ski-icon.png",
    desc: "Old PHP Ski Page",
    link: "https://lucky13s.com/ski2015/index.php",
    type: "a",
  },
  {
    name: "About Me",
    image: "me-icon.png",
    desc: "Who runs this thing",
    type: "a",
    link: "https://zherman.com",
  },
  {
    name: "Info",
    image: "archive-icon.png",
    desc: "Ski Information I may need",
    type: "a",
    link: "https://skierz.net/info",
  },
];
export const Home: React.FC<HomeProps> = (props) => {
  return (
    <div id="main">
      <Gallery>
        {items.map((item, i) => (
          <GalleryItem key={item.name}>
            <Card>
              <CardBody>
                {item.type !== "a" && (
                  <Link to={item.disabled ? "" : item.link}>
                    {item.name}
                    <br />
                    <img src={`./images/${item.image}`} alt={item.name} />
                    <br />
                  </Link>
                )}
                {item.type === "a" && (
                  <a href={item.link}>
                    {item.name}
                    <br />
                    <img src={`./images/${item.image}`} alt={item.name} />
                    <br />
                  </a>
                )}

                {item.disabled && <span>Page Under Construction</span>}
              </CardBody>
            </Card>
          </GalleryItem>
        ))}
      </Gallery>
    </div>
  );
};

export type HomeProps = {};
